<template>
  <client-only>
    <div
      v-show="productsCount > 0 && !isComparePage"
      class="c-btn c-btn-primary compare-btn"
      :class="{ bounce: isBounce, 'pd-compare': isProductPage }"
      @click="compare"
    >

      {{$t("compare")}} <span>{{ productsCount }}</span> product(en)
    </div>
  </client-only>
</template>

<script>
import ClientOnly from "vue-client-only";

export default {
  name: "CompareButton",
  components: {
    ClientOnly,
  },
  data() {
    return {
      isBounce: false,
    };
  },
  computed: {
    products() {
      return this.$store.getters["compare/getProductCompare"];
    },
    productsCount() {
      return this.products && this.products.length ? this.products.length : 0;
    },
    isComparePage() {
      return this.$route.name === "compare";
    },
    isProductPage() {
      return this.$route.name.startsWith("product-");
    },
  },
  watch: {
    productsCount() {
      this.isBounce = true;
      setTimeout(() => {
        this.isBounce = false;
      }, 2000);
    },
  },
  methods: {
    compare() {
      this.$router.push("/compare");
    },
  },
};
</script>

<style lang="scss" scoped>
.compare-btn {
  cursor: pointer;
  position: fixed;
  bottom: 64px;
  right: 100px;
  z-index: 1000;
  background-color: $primaryColor;
  text-align: center;
  color: $white;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 28%);
  font-weight: 400;
  border-radius: 5px;
  font-size: 12px;
  border-color: $primaryColor;
  span{
    font-weight: 600;
  }
  &:hover{
    background-color: $primaryColor;
    color: $white;
  }
}
.bounce {
  animation: bounce 2s;
}

@keyframes bounce {
  20%,
  53%,
  80%,
  0%,
  100% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    transform: translate3d(0, -4px, 0);
  }
}

@media screen and (max-width:560px) {
  .bounce{
    animation: none;
  }
  .compare-btn{
    &.pd-compare{
  bottom: 45px;    
    }
  width: calc(100% - 10px);
  left: 50%;
  transform: translateX(-50%);
  border-radius: 3px;
  box-shadow: none;
  bottom: 0;
  right: 0;
  }
  
}

</style>